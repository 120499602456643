import axios from "axios";
import config from "../config";

axios.defaults.withCredentials = true;

export const getYearsApi = async (data) => {
	try {
		return await axios.get(config.apiUrl + "/get-years", {
			params: data,
		});
	} catch (err) {
		return err.response;
	}
};

export const getManufacturersApi = async (data) => {
	try {
		return await axios.post(
			config.apiUrl + "/get-manufacturers",
			data,
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
	} catch (err) {
		return err.response;
	}
};

export const getMakesApi = async (data) => {
	try {
		return await axios.post(
			config.apiUrl + "/get-makes",
			data,
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
	} catch (err) {
		return err.response;
	}
};

export const getRetailsApi = async (data) => {
	try {
		return await axios.post(
			config.apiUrl + "/get-retail",
			data,
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
	} catch (err) {
		return err.response;
	}
};
