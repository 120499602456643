import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Select, Paper, InputLabel, Box } from "@mui/material";

import { getManufacturersApi, getYearsApi, getMakesApi, getRetailsApi } from "./services";

import RetailTable from "./components/RetailTable";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const MyForm = () => {
  const [totalManufacturers, setTotalManufacturers] = useState([]);
  const [manufacturer, setManufacturer] = useState("");

  const [totalYears, setTotalYears] = useState([]);
  const [year, setYear] = useState("");

  const [totalMakes, setTotalMakes] = useState([]);
  const [make, setMake] = useState("");

  const [tableData, setTableData] = useState({})

  const handleManufacturerChanged = (event) => {
    setTableData({})
    setManufacturer(event.target.value);
    getMakes(event.target.value)
    getRetails(event.target.value, '')
  };

  const handleYearChanged = (event) => {
    setTableData({})
    setYear(event.target.value);
    getManufactures(event.target.value);
    setTotalMakes([])
  };

  const handleMakeChanged = (event) => {
    setTableData({})
    setMake(event.target.value);
    getRetails(manufacturer, event.target.value)
  };

  const getYears = async () => {
    const response = await getYearsApi();
    if (response && response.status < 300) {
      setTotalYears(response.data.data);
    }
  }

  const getManufactures = async (year_value) => {
    const data = {
      "year": year_value,
    }
    const response = await getManufacturersApi(data);
    if (response && response.status < 300) {
      setTotalManufacturers(response.data.data);
    }
  };

  const getMakes = async (manufacturer_value) => {
    const data = {
      "manufacturer_id": manufacturer_value,
      "year": year,
    }
    const response = await getMakesApi(data);
    if (response && response.status < 300) {
      setTotalMakes(response.data.data);
    }
  }


  const getRetails = async (manufacturer_value, make_id) => {
    const data = {
      "manufacturer_id": manufacturer_value,
      "year": year,
      "make_id": make_id,
    }
    const response = await getRetailsApi(data);
    if (response && response.status < 300) {
      let results = response.data.data;
      let cleaned_data = {};
      results.sort((a, b) => a.make_name.localeCompare(b.make_name));

      results.map((item) => {
        if (!(item.make_name in cleaned_data)) {
          cleaned_data[item.make_name] = {
            "make_name": "",
            "header_rows": [],
            "values": [],
          }
        }
        let header_rows = Object.keys(item.info);
        header_rows.unshift("")

        cleaned_data[item.make_name]["make_name"] = item.make_name == "No Make" ? "" : item.make_name;
        cleaned_data[item.make_name]["header_rows"] = header_rows;
        cleaned_data[item.make_name]["values"].push(Object.assign({ "model": item.name }, item.info))
      })
      console.log(cleaned_data)
      setTableData(cleaned_data);
    }
  }

  useEffect(() => {
    getYears();
  }, []);

  return (
    <div className="container">
      <Paper
        className="mx-auto"
        style={{ marginTop: 50, padding: 20, maxWidth: "1200px" }}
      >
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Box sx={{ minWidth: 120 }}>
              <InputLabel
                variant="standard"
                htmlFor="uncontrolled-native-citizenship"
                style={{ whiteSpace: "break-spaces", fontSize: 13 }}
              >
                Year
              </InputLabel>
              <Select
                fullWidth
                defaultValue="0"
                onChange={handleYearChanged}
                size="small"
                style={{ marginTop: 8, fontSize: 13 }}
              >
                <MenuItem
                  key={"0_year"}
                  value="0"
                  style={{ fontSize: 13 }}
                >
                  All
                </MenuItem>
                {totalYears && totalYears.map((option) => (
                  <MenuItem
                    key={option + "_year"}
                    value={option}
                    style={{ fontSize: 13 }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ minWidth: 120 }}>
              <InputLabel
                variant="standard"
                htmlFor="uncontrolled-native-citizenship"
                style={{ whiteSpace: "break-spaces", fontSize: 13 }}
              >
                Manufacturer
              </InputLabel>
              <Select
                fullWidth
                defaultValue="0"
                onChange={handleManufacturerChanged}
                size="small"
                style={{ marginTop: 8, fontSize: 13 }}
              >
                <MenuItem
                  key={"0_manufacture"}
                  value="0"
                  style={{ fontSize: 13 }}
                >
                  All
                </MenuItem>
                {totalManufacturers && totalManufacturers.map((option) => (
                  <MenuItem
                    key={option.id + "_manufacture"}
                    value={option.id}
                    style={{ fontSize: 13 }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ minWidth: 120 }}>
              <InputLabel
                variant="standard"
                htmlFor="uncontrolled-native-citizenship"
                style={{ whiteSpace: "break-spaces", fontSize: 13 }}
              >
                Make
              </InputLabel>
              <Select
                fullWidth
                defaultValue="0"
                onChange={handleMakeChanged}
                size="small"
                style={{ marginTop: 8, fontSize: 13 }}
              >
                <MenuItem
                  key={"0_make"}
                  value="0"
                  style={{ fontSize: 13 }}
                >
                  All
                </MenuItem>
                {totalMakes.map((option) => (
                  <MenuItem
                    key={option.id + "_make"}
                    value={option.id}
                    style={{ fontSize: 13 }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          {tableData && Object.keys(tableData).map((make_name) => (
            <Grid item xs={12} key={make_name}>
              <RetailTable data={tableData[make_name]} />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </div>
  );
};

export default MyForm;
