import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}


export default function RetailTable(props) {
	const { data } = props;
	const make_name = data["make_name"]
	const header_rows = data["header_rows"]
	const values = data["values"]

	return (
		<>
			<p style={{ fontWeight: "Bold" }}>{make_name}</p>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							{header_rows && header_rows.map((h) => (
								<StyledTableCell key={h + "_header"}>{h}</StyledTableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{values.map((row) => (
							<StyledTableRow key={make_name + "_" + row.model}>
								{Object.keys(row).map((info_key) => (
									<StyledTableCell key={make_name + "_" + row.model + "_" + row[info_key]}>{row[info_key]}</StyledTableCell>
								))}
								{/* <StyledTableCell component="th" scope="row">
									{row.name}
								</StyledTableCell>
								<StyledTableCell>{row.calories}</StyledTableCell>
								<StyledTableCell>{row.fat}</StyledTableCell>
								<StyledTableCell>{row.carbs}</StyledTableCell>
								<StyledTableCell>{row.protein}</StyledTableCell> */}
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
